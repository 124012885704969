import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { apiGetCenter, apiGetCenters } from 'services/CentersService';

export const getCenters = createAsyncThunk('centers/data/getCenters',async (data) => {
	const response = await apiGetCenters(data)
	return response.data
})

export const getCenter = createAsyncThunk('centers/data/getCenter', async (data) => {
	return await apiGetCenter(data)
})


export const initialTableData = {
	total: 0,
	pageIndex: 1,
	pageSize: 10,
	query: '',
	sort: {
		order: '',
		key: ''
	}
}

export const initialFilterData = {
	name: '',
	category: [],
	status: [0, 1, 2],
	centerStatus: 0,
}

const dataSlice = createSlice({
	name: 'centers/data',
	initialState: {
		loading: false,
		centersList: [],
		centerData: {},
		tableData: initialTableData,
		filterData: initialFilterData,
	},
	reducers: {
		setTableData: (state, action) => {
			state.tableData = action.payload
		},
		setFilterData: (state, action) => {
			state.filterData = action.payload
		},
	},
	extraReducers: {
		[getCenters.fulfilled]: (state, action) => {
			state.centersList = action.payload
			state.tableData.total = action.payload.length
			state.loading = false
		},
		[getCenters.pending]: (state) => {
			state.loading = true
		},
		[getCenter.fulfilled]: (state, action) => {
			state.centerData = action.payload.data
			state.loading = false
		},
		[getCenter.pending]: (state) => {
			state.loading = true
		},
	}
})

export const {setTableData, setFilterData } = dataSlice.actions

export default dataSlice.reducer

import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import fr from 'date-fns/locale/fr';
import classNames from 'classnames';

registerLocale('fr', fr);

export default function TimePicker ({startDate,handleChange}) {
	return <>
		<DatePicker
			className={classNames('w-full rounded-md border border-gray-300 bg-white py-2.5 pl-3 pr-10 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 sm:text-sm')}
			selected={startDate}
			onChange={handleChange}
			showTimeSelect
			locale="fr"
			timeIntervals={15}
			timeCaption="Horaire"
			dateFormat="MMMM d, yyyy HH:mm"
		/>
	</>;
}
import React, { createContext, useEffect, useState } from "react";
import io from "socket.io-client";
import { pushNotification } from "../../utils/notificationHelper";
import { useLocation } from "react-router-dom";
import doorOpenSound from "assets/sounds/Shop Door Bell.mp3";
import doorclose from "assets/sounds/doorclose.mp3"; // Import the sound file
import failSound from "assets/sounds/Fail Sound Effect.mp3";
import cashinSound from "assets/sounds/cashin.mp3";
import taskSound from "assets/sounds/taskSound.mp3";
import { useSelector } from "react-redux";
// Play the sound

export const SocketContext = createContext();

const SocketProvider = ({ children }) => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const currentChatId = queryParams.get("chat");
  const [socket, setSocket] = useState(null);
  const [newMessageData, setNewMessageData] = useState(null);
  const user = useSelector((state) => state.auth.user);
  const sessionCenter = useSelector(
    (state) => state.centers.state.sessionCenter
  );

  // useEffect(() => {
  //   if (user._id && sessionCenter._id) {
  //     // only connect if user._id and sessionCenter._id exist
  //     const socketConn = io.connect(
  //       process.env.REACT_APP_REST_API_ENDPOINT_KEY,
  //       {
  //         query: {
  //           userId: user._id,
  //           centerId: sessionCenter._id,
  //         },
  //       }
  //     );
  //     setSocket(socketConn);

  //     return () => {
  //       if (socketConn) {
  //         socketConn.disconnect();
  //       }
  //     };
  //   }
  // }, [user, sessionCenter]);

  // useEffect(() => {
  //   socket?.on("notify patient arrival", (data) => {
  //     pushNotification("Le patient est lá ! ", data.message);
  //     const audio = new Audio(doorOpenSound);
  //     audio.play();
  //   });

  //   socket?.on("notify room change", (data) => {
  //     pushNotification("Changement de salle !", data.message, "success", 7500);
  //     const audio = new Audio(doorclose);
  //     audio.play();
  //   });

  //   socket?.on("notify patient decline appointment", (data) => {
  //     pushNotification(data.message, data.motif, "danger", 7500);
  //     const audio = new Audio(failSound);
  //     audio.play();
  //   });

  //   socket?.on("notify patient payed session", (data) => {
  //     pushNotification(data.title, data.description, "success", 7500);
  //     const audio = new Audio(cashinSound);
  //     audio.play();
  //   });

  //   socket?.on("notify patient done subscription session", (data) => {
  //     pushNotification(data.title, data.description, "success", 7500);
  //     const audio = new Audio(cashinSound);
  //     audio.play();
  //   });

  //   socket?.on("notify task assigned", (data) => {
  //     pushNotification(
  //       "Une tâche vient de vous être assignée",
  //       data.title,
  //       "success",
  //       7500
  //     );
  //     const audio = new Audio(taskSound);
  //     audio.play();
  //   });

  //   socket?.on("notify task moved to new board", (data) => {
  //     console.log(data);
  //     pushNotification(
  //       `Une tâche vient d'être modifieée`,
  //       `${data.message}`,
  //       "success",
  //       7500
  //     );
  //     const audio = new Audio(taskSound);
  //     audio.play();
  //   });

  //   socket?.on("notify task new comment", (data) => {
  //     pushNotification(data.title, `${data.description}`, "success", 7500);
  //     const audio = new Audio(taskSound);
  //     audio.play();
  //   });

  //   const handleNewMessage = (data) => {
  //     console.log(
  //       location.pathname.startsWith("/messages/conversations") &&
  //         data.senderId === currentChatId
  //     );
  //     if (
  //       location.pathname.startsWith("/messages/conversations") &&
  //       data.senderId === currentChatId
  //     ) {
  //       setNewMessageData(data.message);
  //       // fetch the new chat
  //     } else {
  //       pushNotification("New message", data.message);
  //     }
  //   };

  //   socket?.on("notify message received", handleNewMessage);

  //   return () => {
  //     socket?.off("notify message received", handleNewMessage);
  //   };
  // }, [socket, location]);

  return (
    <SocketContext.Provider value={{ socket, newMessageData }}>
      {children}
    </SocketContext.Provider>
  );
};

export default SocketProvider;

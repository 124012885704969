const appConfig = {
    apiBaseUrl: process.env.REACT_APP_REST_API_ENDPOINT_KEY,
    apiPrefix: '/api',
    authenticatedEntryPath: '/statistiques',
    adminAuthenticatedEntryPath: '/statistiques',
    notAdminaAuthenticatedEntryPath: '/liste-consultations',
    unAuthenticatedEntryPath: '/sign-in',
    tourPath: '/',
    enableMock: false
}

export default appConfig
import { createSlice } from '@reduxjs/toolkit';

const stateSlice = createSlice({
	name: 'centers/state',
	initialState: {
		deleteConfirmation: false,
		selectedCenter: '',
		sessionCenter: {},
		addModal: false,
		chooseModal: false,
		editModal: false,
		sortedColumn: () => {
		}
	},
	reducers: {
		toggleDeleteConfirmation: (state, action) => {
			state.deleteConfirmation = action.payload;
		},
		toggleAddModal: (state, action) => {
			state.addModal = action.payload;
		},
		toggleChooseModal: (state, action) => {
			state.chooseModal = action.payload;
		},
		toggleEditModal: (state, action) => {
			state.editModal = action.payload;
		},
		setSortedColumn: (state, action) => {
			state.sortedColumn = action.payload;
		},
		setSelectedCenter: (state, action) => {
			state.selectedCenter = action.payload;
		},
		setSessionCenter: (state, action) => {
			state.sessionCenter = action.payload;
		}
	}
});

export const {
	toggleDeleteConfirmation,
	setSortedColumn,
	toggleAddModal,
	toggleEditModal,
	setSelectedCenter,
	toggleChooseModal,
	setSessionCenter
} = stateSlice.actions;

export default stateSlice.reducer;

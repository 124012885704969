import ApiService from "./ApiService"

const endpoint = '/centers'


export async function apiGetCenters (data) {
	return ApiService.fetchData({
		url: `${endpoint}`,
		method: 'get',
		data
	})
}

export async function apiCreateCenter (data) {
	return ApiService.fetchData({
		url: `${endpoint}`,
		method: 'post',
		data
	})
}

export async function apiEditCenter (data, id) {
	return ApiService.fetchData({
		url: `${endpoint}/${id}`,
		method: 'patch',
		data
	})
}

export async function apiGetCenter (data) {
	return ApiService.fetchData({
		url: `${endpoint}/${data}`,
		method: 'get',
		data
	})
}

export async function apiDeleteCenter (data) {
	return ApiService.fetchData({
		url: `${endpoint}/${data}`,
		method: 'delete',
		data
	})
}


